<!-- 文件管理 -->
<template>
	<div class="doctor_management">
	<el-row class="bread_crumbs">
		<el-breadcrumb separator="/">
			<el-breadcrumb-item :to="{ path: '/FileManagement' }">文件管理</el-breadcrumb-item>
			<el-breadcrumb-item >文件列表</el-breadcrumb-item>
		</el-breadcrumb>
	</el-row>
	<div class="public_bg">
		<el-row>
			<el-col :span="24">
				<div class="grid-content">
					<template>
						<div class="form-style">
							<el-form ref="formInline" :inline="true" :model="formInline" label-width="100px" class="demo-form-inline">
								<el-form-item label="文件名称:" prop="fileName">
									<el-input clearable v-model="formInline.fileName" placeholder="请输入"></el-input>
								</el-form-item>
								<el-form-item label="产品序列号:" prop="fileNum">
									<el-input clearable v-model="formInline.fileNum" placeholder="请输入"></el-input>
								</el-form-item>
								<!-- <el-form-item v-if="types=='200029'" label="厂家" prop="manufacturer">
									<el-select filterable style="width:100%" v-model="formInline.manufacturer" placeholder="请选择">
										<el-option v-for="item in ManufactureSelectOptions" :key="item.no" :label="item.label" :value="item.no">
										</el-option>
									</el-select>
								</el-form-item> -->
								<el-form-item v-if="types=='200030'" label="医院科室医生:" prop="ModelList">
									<el-cascader v-model="formInline.ModelList" clearable style="width:100%" placeholder="请选择" filterable :props="{ value: 'no', label: 'label'}"
									 :options="CurrencyAllOptions">
									</el-cascader>
								</el-form-item>
								<el-form-item label="文件状态:" prop="code">
									<el-select clearable filterable ref="selection" style="width:100%"  v-model="formInline.code" placeholder="请选择">
										<el-option
										  v-for="item in statusOptions"
										  :key="item.no"
										  :label="item.label"
										  :value="item.no">
										</el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="时间:" prop="timeList">
									<el-date-picker
									style="width: 222.44px;"
									  v-model="formInline.timeList"
									  type="datetimerange"
									  start-placeholder="开始时间"
									  value-format="yyyy-MM-dd HH:mm:ss"
									  end-placeholder="结束时间"
									  :default-time="['12:00:00']">
									</el-date-picker>
									<!-- <el-col :span="11">
										<el-form-item prop="dateStart">
											<el-date-picker value-format="yyyy-MM-dd HH:mm:ss" clearable type="datetime" placeholder="开始时间" v-model="formInline.dateStart"
											 style="width: 100%;"></el-date-picker>
										</el-form-item>
									</el-col>
									<el-col class="line" :span="1">~</el-col>
									<el-col :span="11">
										<el-form-item prop="dateEnd">
											<el-date-picker value-format="yyyy-MM-dd HH:mm:ss" clearable type="datetime" placeholder="结束时间" v-model="formInline.dateEnd"
											 style="width: 100%;"></el-date-picker>
										</el-form-item>
									</el-col> -->
								</el-form-item>
								
								<el-form-item>
									<el-button type="primary" @click="querys">查询</el-button>
									<el-button @click="resetForm('formInline')">重置</el-button>
									<el-button type="primary" icon="el-icon-plus" @click="UploadFile" v-if="$store.state.outerbtns.includes('WJGLUPLOAD')">新增</el-button>
								</el-form-item>
							</el-form>
						</div>
						<div class="tableMargin">
							<el-table stripe ref="multipleTable" :header-cell-style="{background:'#FAFAFA'}" :data="InspectionData" style="width: 100%">
								<el-table-column align="left" width="50px" prop="num" label="序号">
								</el-table-column>
								<el-table-column align="left" prop="fileName" label="文件名称">
								</el-table-column>
								<el-table-column align="left" prop="fileNum" label="序列号">
								</el-table-column>
								<el-table-column align="left" prop="statusLabel" label="文件状态">
									<template slot-scope="scope">
										<el-badge is-dot v-if="scope.row.status=='200' && scope.row.statusLabel=='文件已处理'" type="success" style="padding-top: 8px;"></el-badge>
										<el-badge is-dot v-if="scope.row.status=='200' && scope.row.statusLabel=='OCR文件已处理,待确认'" type="primary" style="padding-top: 8px;"></el-badge>
										<el-badge is-dot v-if="scope.row.status!='200'" type="error" style="padding-top: 8px;"></el-badge>
										{{scope.row.statusLabel}}
									</template>
								</el-table-column>
								<el-table-column align="left" prop="hospital" label="医院">
								</el-table-column>
								<el-table-column align="left" prop="departments" label="科室">
								</el-table-column>
								<el-table-column align="center" prop="doctor" label="医生">
								</el-table-column>
								<el-table-column align="left" prop="manufacturer" label="厂家">
								</el-table-column>
								<el-table-column align="left" prop="creationDate" label="上传时间">
								</el-table-column>
								<el-table-column align="left" label="操作">
									<template slot-scope="scope">
										<el-button type="text" size="small" >
											<a :href="scope.row.fileUrl" style="color:#0193D8;" target="_blank">查看文件</a>
										</el-button>
										<div v-show="$store.state.btns.includes('WJGLADDALLINFO')">
										<el-button type="text" size="small" v-if="scope.row.typeInfo=='1'" @click="jump(scope.row,scope.row.typeInfo,scope.row.id)">新增病患信息</el-button>
										</div>
										<div v-show="$store.state.btns.includes('WJGLADDINFO')">
										   <el-button type="text" size="small" v-if="scope.row.typeInfo=='2'" @click="jump(scope.row,scope.row.typeInfo,scope.row.id)">新增随访信息</el-button>
									    </div>
										<div >
											<template v-if="scope.row.status">
										       <el-button type="text" size="small" v-if="scope.row.status!='200' && scope.row.status!='250'" @click="jumps(scope.row.status,scope.row)">手动新增病患信息</el-button>
										     </template>
										</div>
									</template>
								</el-table-column>
							</el-table>
						</div>
						<v-paging v-on:childPage="pages" :total=total></v-paging>
					</template>
				</div>
			</el-col>
		</el-row>
		<!-- 上传检查报告模态框 -->
		<el-dialog title="提示" :visible.sync="dialogVisible" width="70%">
			<el-row type="flex" align="middle">
				<span style="margin-right: 20px;">文件路径</span>
				<el-upload ref="upload" :on-change="handleChange" class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/"
				 :on-preview="handlePreview" :on-remove="handleRemove" :before-remove="beforeRemove" :on-exceed="handleExceed"
				 multiple :limit="25" :auto-upload="false" :file-list="fileList">
					<el-button size="small" type="primary">选择文件</el-button>
				</el-upload>
			</el-row>
			<span slot="footer" class="dialog-footer" style="text-align: center; ">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="dialogVisibleSave">上传</el-button>
			</span>
		</el-dialog>
		<!--新增模态框  -->
		<el-dialog title="新增文件" :visible.sync="DialogChangeVisible" width="40%">
			<el-form :model="ruleFormChange" :rules="rulesDelete" ref="ruleFormChange" label-width="120px" class="demo-ruleForm">
				<el-form-item v-if="types=='200030'" label="医院科室医生:" prop="ModelList">
					<el-cascader ref="refSubCat" v-model="ruleFormChange.ModelList" clearable style="width:100%" placeholder="请选择"
					 filterable :props="{ value: 'no', label: 'label'}" :options="CurrencyAllOptions">
					</el-cascader>
				</el-form-item>
				<!-- <el-form-item v-if="types=='200029'" label="厂家" prop="manufacturer">
					<el-select filterable style="width:100%" v-model="ruleFormChange.manufacturer" placeholder="请选择">
						<el-option v-for="item in ManufactureSelectOptions" :key="item.no" :label="item.label" :value="item.no">
						</el-option>
					</el-select>
				</el-form-item> -->
				<!-- <span style="color:red;margin-left:120px">请谨慎选择</span> -->
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="DialogChangeVisible = false">取 消</el-button>
				<el-button type="primary" @click="DialogChangeVisibleSave">确 认</el-button>
			</span>
		</el-dialog>
	</div>
	</div>
</template>

<script>
	import vPaging from '../common/Paging.vue' //引入分页组件
	export default {
		components: {
			vPaging
		},
		methods: {
			handleExceed(files, fileList) {
				this.$message.warning(`最多只能上传25个文件!`);
			},
			//获取文件内容接口
			async QueryInspectionCurrency(type, id) {
				let obj = {
					menuId: this.menuCode,
					id: id
				}
				let res = await this.$Http.InspectionCurrency(obj);
				// if (res.code == '200') {
					console.log(type)
					if (type == "1") {
						this.$router.push({
							path: '/NewAddPatient'
						})
						localStorage.setItem("fileFile", "1");
					} else {
						this.$router.push({
							path: '/CheckRecordNew'
						})
						localStorage.setItem("fileFile", "2")
					}
					res.code == '200' ? localStorage.setItem("InspectionCurrencyList", JSON.stringify(res.data)) : '';
				// } else {
					// this.$message.error(res.message);
				// }
			},
			jump(item,type, id) {
				localStorage.setItem("fileItem", JSON.stringify(item));
				localStorage.setItem("medicalData", JSON.stringify(item));
				this.QueryInspectionCurrency(type, id);
			},
			//手动新增病患信息
			jumps(status,item){
				// if(!(status==200 || status==250)){
					this.$router.push({
						path: '/NewAddPatient',
						
					})
					localStorage.setItem("fileFile", "1")
					localStorage.setItem("fileNameFile", item.fileName)
					localStorage.setItem("InspectionCurrencyListFile", item.fileUrl)
					localStorage.setItem("fileItem", JSON.stringify(item));
					localStorage.setItem("InspectionCurrencyList", JSON.stringify(item));
				// }
			},
			//查询医院科室医生级联
			async QueryCurrencyAll() {
				let obj = {
					menuId: this.menuCode,
				}
				let res = await this.$Http.CurrencyAll(obj);
				if (res.code == '200') {
					this.CurrencyAllOptions = res.data;
				} else {
					this.$message.error(res.message);
				}
			},
			//厂家下拉
			async QueryManufactureSelect() {
				let obj = {
					parentCode: "100010",
					menuId: this.menuCode,
				}
				let res = await this.$Http.ManufactureSelect(obj);
				if (res.code == '200') {
					this.ManufactureSelectOptions = res.data;
				} else {
					this.$message.error(res.message);
				}
			},
			//分页接口
			async QueryInspectionPage(current = 1, size = 10) {
				let info = {
					current: current,
					size: size,
					fileName: this.formInline.fileName,
					menuId: this.menuCode,
					fileNum: this.formInline.fileNum,
					hospitalId: this.formInline.ModelList ? this.formInline.ModelList[0] : "",
					departmentsId: this.formInline.ModelList ? this.formInline.ModelList[1] : "",
					userId: this.formInline.ModelList ? this.formInline.ModelList[2] : "",
					dateStart:this.formInline.timeList ? this.formInline.timeList[0] :"",
					dateEnd:this.formInline.timeList ? this.formInline.timeList[1] : "",
					code:this.formInline.code,
					//manufacturer: this.formInline.manufacturer,
				}
				let res = await this.$Http.InspectionPage(info)
				if (res.code == '200') {
					this.InspectionData = res.data.data;
					this.total = res.data.total;
				} else {
					this.InspectionData = [];
					this.total = 0;
					this.$message.error(res.message);
				}
			},

			handleRemove(file, fileList) {
				this.fileParam = {};
			},
			handlePreview(file) {
				console.log(file);
			},
			beforeRemove(file, fileList) {
				return this.$confirm(`确定移除 ${ file.name }？`);
			},
			handleChange(file, fileList) {
				let excelfileExtend = ".PDF,.pdf" //设置文件格式
				let fileExtend = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
				if (excelfileExtend.indexOf(fileExtend) <= -1) {
					this.$refs.upload.clearFiles();
					this.$message.error('文件格式错误')
					return false
				}
				console.log(file,fileList.raw)
				this.fileParam = fileList;
			},
			toFormData(val) {
				let formData = new FormData();
				for (let i in val) {
					isArray(val[i], i);
				}
			    function isArray(array, key) {
					if (array == undefined || typeof array == "function") {
						return false;
					}
					if (typeof array != "object") {
						formData.append(key, JSON.parse(JSON.stringify(array)));
					} else if (array instanceof Array) {
						if (array.length == 0) {
							formData.append(`${key}`, "");
						} else {
							for (let i in array) {
								for (let j in array[i]) {
									isArray(array[i][j], `${key}[${i}].${j}`);
								}
							}
						}
					} else {
						let arr = Object.keys(array);
						if (arr.indexOf("uid") == -1) {
							for (let j in array) {
								isArray(array[j], `${key}.${j}`);
							}
						} else {
							formData.append(`${key}`,array);
						}
					}
				}
				return formData;
			},
			//上传文件接口
			async QueryInspectionUploadFile() {
				let newParams = new FormData();
				this.fileParam.map((item)=>{
					newParams.append("fileParam",item.raw);
				})
				let obj = {
					...this.refSubCat
				}
				for(let key in obj){
					newParams.append(key,obj[key])
				}
				let res = await this.$Http.InspectionUploadFile(newParams);
				this.QueryInspectionPage();
				this.DialogChangeVisible = false;
				this.dialogVisible = false;
				// if (res.code == 200) {
				// 	this.$message.success(res.message);
				// } else {
				// 	this.$message.error(res.message);
				// }
			},
			//保存
			dialogVisibleSave() {
				this.QueryInspectionUploadFile();
			},
			//分页组件
			pages(current, size) {
				this.QueryInspectionPage(current, size);
			},
			//上传文件
			UploadFile() {
				this.types = localStorage.getItem("type");
				this.fileParam = {};
				this.fileList=[];
				
				if (this.types == "200030") {
					this.DialogChangeVisible = true;
					this.ruleFormChange.ModelList=[];
				}else{
					this.dialogVisible = true;
				}
			},
			//保存
			DialogChangeVisibleSave() {
				this.$refs["ruleFormChange"].validate((valid) => {
					if (valid) {
						if (this.types == "200030") {
							this.refSubCat = {
								hospital: this.ruleFormChange.ModelList[0],
								departments: this.ruleFormChange.ModelList[1],
								doctor: this.ruleFormChange.ModelList[2],
							}
							this.DialogChangeVisible = false;
							this.dialogVisible = true;
						}
						if (this.types == "200029") {
							this.refSubCat = {
								//manufacturer: this.ruleFormChange.manufacturer,
							}
						}
						
					} else {
						return false;
					}
				});
			},
			// 搜索
			querys() {
				this.QueryInspectionPage();
			},
			//重置
			resetForm(formName) {
				this.$refs[formName].resetFields();
			}
		},
		data() {
			return {
				timeList:[],
				statusOptions:[{
					label:"文件已处理,待确认",
					no:"1"
				},{
					label:"文件已处理",
					no:"2"
				},{
					label:"其他",
					no:"3"
				}],
				types:localStorage.getItem("type"),
				ManufactureSelectOptions: [],
				CurrencyAllOptions: [],
				ruleFormChange: {
					ModelList:[]
				},
				rulesDelete: {
					ModelList: [{
						required: true,
						message: '请选择医院科室医生',
						trigger: 'change'
					}],
				},
				DialogChangeVisible: false,
				menuCode: JSON.parse(localStorage.getItem('menuList'))[this.$route.name],
				fileList: [],
				dialogVisible: false,
				//form表单查询
				formInline: {},
				//table列表
				InspectionData: [],
				//总条数
				total: 0,
			}
		},
		created() {},
		mounted() {
			this.QueryInspectionPage();
			//查询医院科室医生级联
			this.QueryCurrencyAll();
			//厂家下拉
			this.QueryManufactureSelect();
		}
	}
</script>

<style scoped>
</style>
